(function ($) {
    "use strict";

    // Spinner
    var spinner = function () {
        setTimeout(function () {
            if ($('#spinner').length > 0) {
                $('#spinner').removeClass('show');
            }
        }, 1);
    };
    spinner();
    
    
    // Initiate the wowjs
    new WOW().init();


    // Sticky Navbar

    $(window).scroll(function () {
        if ($(this).scrollTop() > 45) {
            $('.navbar').addClass('sticky-top shadow-sm');
        } else {
            $('.navbar').removeClass('sticky-top shadow-sm');
        }
    });

    // Screenshot carousel
    
    $(".screenshot-carousel").owlCarousel({
        autoplayTimeout:2500,
        autoplay: true,
        smartSpeed: 900,
        loop: true,
        dots: true,
        items: 1
    });
    
    // Back to top button
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            $('.back-to-top').fadeIn('slow');
        } else {
            $('.back-to-top').fadeOut('slow');
        }
    });
    $('.back-to-top').click(function () {
        $('html, body').animate({scrollTop: 0}, 1500, 'easeInOutExpo');
        return false;
    });


    // Facts counter
    $('[data-toggle="counter-up"]').counterUp({
        delay: 10,
        time: 2000
    });


    // Date and time picker
    $('.date').datetimepicker({
        format: 'L'
    });
    $('.time').datetimepicker({
        format: 'LT'
    });


    // Header carousel
    $(".header-carousel").owlCarousel({
        autoplay: true,
        smartSpeed: 1500,
        loop: true,
        nav: false,
        dots: true,
        items: 1,
        dotsData: true,
    });


    // Testimonials carousel
    
    $(".testimonial-carousel").owlCarousel({
        autoplay: true,
        autoplayTimeout:3500,
        smartSpeed: 900,
        margin: 25,
        dots: false,
        loop: true,
        center: true,
        responsive: {
            0:{
                items:1
            },
            576:{
                items:1
            },
            768:{
                items:2
            },
            992:{
                items:3
            }
        }
    });

    // Client carousel
    $(".client-carousel").owlCarousel({
        autoplay: true,
        autoplayTimeout:2200,
        smartSpeed: 500,
        center: true,
        dots: false,
        loop: true,
        nav : true,
        navText : [
            '<i class="bi bi-arrow-left"></i>',
            '<i class="bi bi-arrow-right"></i>'
        ],
        responsive: {
            0:{
                items:1
            },
            768:{
                items:2
            },
            992:{
                items:3
            }
        }
    });

    // Modal Video
    $(document).ready(function () {
        var $videoSrc;
        $('.btn-play').click(function () {
            $videoSrc = $(this).data("src");
        });
        console.log($videoSrc);

        $('#videoModal').on('shown.bs.modal', function (e) {
            $("#video").attr('src', $videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
        })

        $('#videoModal').on('hide.bs.modal', function (e) {
            $("#video").attr('src', $videoSrc);
        })
    });
    
})(jQuery);

function create_lead(event) {
    // var xhr = new XMLHttpRequest();
    var name = document.getElementById('name').value;
    var email = document.getElementById('email').value;
    var subject = document.getElementById('subject').value;
    var message = document.getElementById('message').value;
    
    var url = "https://thirvusoft.co.in/api/method/thirvu_web.public.py.lead.create_lead";
    var nameValue = encodeURIComponent(name);
    var emailValue = encodeURIComponent(email);
    var messageValue = encodeURIComponent(message);
    var subjectValue = encodeURIComponent(subject);
    
    fetch(url, {
        method: 'POST', // or 'GET' based on your server expectations
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `name=${nameValue}&email=${emailValue}&message=${messageValue}&subject=${subjectValue}`
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.text();
    })
    .then(data => console.log(data))
    .catch(error => console.error('Fetch Error:', error));
    
}

const myTimeout = setTimeout(contactus, 10000);

function contactus() {
    if(window.location.pathname != "/contact_us/new" ){
        document.getElementById('id01').style.display='block'
    }
}


var modal = document.getElementById('id01');

window.onclick = function(event) {
    if (event.target == modal) {
        modal.style.display = "none";
    }
}